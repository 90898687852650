import { EyeOpen } from '../../../assets/svgs';
import { InstitutionUserType, ServerGetResponse } from '../../../types';
import { getDateTime, handleApiError } from '../../../utilities';
import DeleteButton from './DeleteButton';
import { ConfirmModal } from '../../../components';
import { useState } from 'react';
import { useDeleteUser, useNotify } from '../../../hooks';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';

const InstitutionTable = ({
	page,
	users,
	handleItemClick,
}: {
	page: number;
	users: ServerGetResponse<InstitutionUserType>;
	handleItemClick: (id: string) => void;
}) => {
	const notify = useNotify();
	const queryClient = useQueryClient();
	const [confirmModal, setConfirmModal] = useState(false);
	const [userToDelete, setUserToDelete] = useState<InstitutionUserType>();
	const { mutate: deleteUser, isLoading: isDeleting } = useDeleteUser();

	const handleDelete = (id: string) => {
		setConfirmModal(false);

		deleteUser(id, {
			onSuccess: () => {
				queryClient.invalidateQueries(['dashboard-users']);
				notify({ message: 'User has been deleted', status: 'success', toastOptions: { toastId: 'delete_user_success' } });
			},
			onError: (error) =>
				notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'delete_user_error' } }),
		});
	};

	return (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Institution Name</th>
							<th>No of Department</th>
							<th>Mobile Contact</th>
							<th>Institution Email</th>
							<th>Residing Address</th>
							<th>Country</th>
							<th>Registration Date</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{users?.results?.map((user, index) => (
							<tr key={user?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>{user?.institution_name}</td>
								<td>{user?.department_count}</td>
								<td>{user?.contact}</td>
								<td>{user?.email}</td>
								<td>{user?.institution_address}</td>
								<td>{user?.country}</td>
								<td>
									{getDateTime(user?.created_at, 'mm dd yyyy')}, {getDateTime(user?.created_at, undefined, false)}
								</td>
								<td>
									<div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
										<EyeOpen onClick={() => handleItemClick(user?.id)} />

										{process.env.NODE_ENV === 'development' ? (
											<DeleteButton
												disabled={isDeleting}
												onClick={() => {
													if (isDeleting) return;

													setUserToDelete(user);
													setConfirmModal(true);
												}}
											/>
										) : null}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{users?.results?.map((user) => (
				<div key={user?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Institution Name</b>
							<p>{user?.institution_name}</p>
						</div>

						<div className="action-con" onClick={() => handleItemClick(user?.id)}>
							<EyeOpen />
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>No of Department</b>
							<p>{user?.department_count}</p>
						</div>
						<div>
							<b>Mobile Contact</b>
							<p>{user?.contact}</p>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Institution Email</b>
							<p>{user?.email}</p>
						</div>
						<div>
							<b>Country</b>
							<p>{user?.institution_address}</p>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Registration Date</b>
							<p>{getDateTime(user?.created_at, 'mm dd yyyy')}</p>
						</div>
						<div>
							<b>Residing Address</b>
							<p>{user?.country}</p>
						</div>
					</div>
					<div>
						<b>Registration Time</b>
						<p>
							{getDateTime(user?.created_at, 'mm dd yyyy')}, {getDateTime(user?.created_at, undefined, false)}
						</p>
					</div>
				</div>
			))}

			{confirmModal ? (
				<ConfirmModal
					title="Delete User"
					body={
						<>
							Are you sure you want to delete <b>{userToDelete?.email}</b> account ?<br />
							This action <b style={{ color: '#ff7542' }}>cannot be reversed</b>
						</>
					}
					delayYesInSeconds={5}
					handleClose={() => setConfirmModal(false)}
					handleNo={() => setConfirmModal(false)}
					handleYes={() => userToDelete?.id && handleDelete(userToDelete?.id)}
				/>
			) : null}
		</>
	);
};
export default InstitutionTable;
