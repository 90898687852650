import { useState, useEffect } from 'react';
import { ChevronDown, Education, EducationActive, ID, IDActive, ProfileActive } from '../../../assets/svgs';
import ApplicantProfile from './ApplicantProfile';
import EducationExperience from './EducationExperience';
import IDDocument from './IDDocument';
import { useDecodedUser, useGetUserById, useLogout, useNotify, useUser } from '../../../hooks';

const AsIntern = () => {
	const user = useDecodedUser();
	const logout = useLogout();
	const notify = useNotify();
	const { data: userDetails } = useUser();
	const { data: configDetails } = useGetUserById({ id: user?.user_id });

	const [step, setStep] = useState(1);
	const [formData, setFormData] = useState({
		applicant_name: '',
		applicant_type: '',
		level_of_education: '',
		area_of_study: '',
		institution: '',
		id_card: {
			name: '',
			file: null,
		},
		cv: {
			name: '',
			file: null,
		},
		resume: {
			name: '',
			file: null,
		},
		applicant_avatar: {
			name: '',
			file: null,
		},
		enrolment_document: {
			name: '',
			file: null,
		},
		certification_document: {
			name: '',
			file: null,
		},
		supplementary_document: {
			name: '',
			file: null,
		},
		introduction: '',
		skills: [],
		interested_role: [],
		proficiency_level: '',
		sector: [],
		start_date: '',
		experiences: [
			{
				employer_name: '',
				role: '',
				duration: '',
			},
		],
		previous_experiences: [],
		duration: '',
		required_doc: 'ID Card',
	});

	const handleChange = (event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name]: value }));
	};
	const handleLogout = () => {
		logout(true);
		notify({ message: "You've been logged out successfully", toastOptions: { toastId: 'logout_success' } });
	};

	useEffect(() => {
		const currentStep = document.querySelector(`#step${step}`);

		currentStep.parentElement.scroll(currentStep.offsetLeft, 0);
	}, [step]);

	useEffect(() => {
		if (!userDetails) return;

		setFormData((prev) => ({
			...prev,
			applicant_name: userDetails?.name || '',
			introduction: userDetails?.about || '',
			applicant_type: userDetails?.applicant_type || '',
			applicant_avatar: { name: userDetails?.avatar ? userDetails?.avatar?.split('/')?.pop() || 'Avatar' : '', file: null },
			area_of_study: userDetails?.area_of_study || '',
			level_of_education: userDetails?.highest_edu_level || '',
			institution: userDetails?.recent_institution || '',
			id_card: { name: userDetails?.id_card?.[0]?.file_name || '', file: null },
			previous_experiences: userDetails?.experience
				? userDetails?.experience.map((experience) => ({
						id: experience?.id,
						employer_name: experience?.employer_name || '',
						role: experience?.role || '',
						duration: experience?.duration || '',
				  }))
				: [
						{
							employer_name: '',
							role: '',
							duration: '',
						},
				  ],
			skills: userDetails?.skills || [],
			proficiency_level: userDetails?.english_proficiency_level || '',
			interested_role: userDetails?.interested_role || [],
			sector: userDetails?.interested_sector || [],
			start_date: userDetails?.start_date || '',
			duration: userDetails?.program_duration || '',
		}));
	}, [userDetails]);

	useEffect(() => {
		if (configDetails?.intern_profile_step_1_complete === false) {
			setStep(1);
		} else if (configDetails?.intern_profile_step_2_complete === false) {
			setStep(2);
		} else if (configDetails?.intern_profile_step_3_complete === false) {
			setStep(3);
		}
	}, [configDetails]);

	return (
		<>
			{step !== 1 ? (
				<button className="back-btn" onClick={() => setStep((prev) => prev - 1)}>
					<ChevronDown /> <span>Go Back</span>
				</button>
			) : null}
			<div className="steps-con">
				<div id="step1" className="step active short">
					<ProfileActive />
					<ProfileActive />

					<div>
						<b>Applicant Profile</b>
						<span>Step 01</span>
					</div>
				</div>
				<div id="step2" className={step > 1 ? 'step active short' : 'step short'}>
					<Education />
					<EducationActive />

					<div>
						<b>Education & Experience</b>
						<span>Step 02</span>
					</div>
				</div>
				<div id="step3" className={step > 2 ? 'step active short' : 'step short'}>
					<ID />
					<IDActive />

					<div>
						<b>ID Document, Interest & Skills</b>
						<span>Step 03</span>
					</div>
				</div>
			</div>
			{step === 1 ? (
				<ApplicantProfile
					formData={formData}
					setFormData={setFormData}
					handleChange={handleChange}
					setStep={setStep}
					handleLogout={handleLogout}
				/>
			) : step === 2 ? (
				<EducationExperience formData={formData} handleChange={handleChange} setStep={setStep} handleLogout={handleLogout} />
			) : (
				<IDDocument formData={formData} handleChange={handleChange} handleLogout={handleLogout} />
			)}
		</>
	);
};

export default AsIntern;
