import { Link } from 'react-router-dom';
import { EyeOpen } from '../../../assets/svgs';
import { AdminDashboardInternUserType, ServerGetResponse } from '../../../types';
import { getDateTime, handleApiError, isInstitutionIntern } from '../../../utilities';
import { Approutes } from '../../../constants';
import DeleteButton from './DeleteButton';
import { ConfirmModal } from '../../../components';
import { useState } from 'react';
import { useDeleteUser, useNotify } from '../../../hooks';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';

const InternTable = ({ page, users }: { page: number; users: ServerGetResponse<AdminDashboardInternUserType> }) => {
	const notify = useNotify();
	const queryClient = useQueryClient();
	const [confirmModal, setConfirmModal] = useState(false);
	const [userToDelete, setUserToDelete] = useState<AdminDashboardInternUserType>();
	const { mutate: deleteUser, isLoading: isDeleting } = useDeleteUser();

	const handleDelete = (id: string) => {
		setConfirmModal(false);

		deleteUser(id, {
			onSuccess: () => {
				queryClient.invalidateQueries(['dashboard-users']);
				notify({ message: 'User has been deleted', status: 'success', toastOptions: { toastId: 'delete_user_success' } });
			},
			onError: (error) =>
				notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'delete_user_error' } }),
		});
	};

	return (
		<>
			<div className="table-container">
				<table>
					<thead>
						<tr>
							<th>S/N</th>
							<th>Intern's Name</th>
							<th>Add-Ons Plan</th>
							<th>Intern Type</th>
							<th>Program Start</th>
							<th>Program Timeline</th>
							<th>Registration Time</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{users?.results?.map((user, index) => (
							<tr key={user?.id}>
								<td>{(page - 1) * 10 + index + 1}</td>
								<td>{user?.name || 'N/A'}</td>
								<td>{user?.business_plan?.length ? user?.business_plan?.join(', ') : 'N/A'}</td>
								<td>{isInstitutionIntern(user) ? 'Institution Intern' : 'Intern'}</td>
								<td>{user?.start_date ? getDateTime(user?.start_date, 'mm dd yyyy') : 'N/A'}</td>
								<td>{user?.program_duration || 'N/A'}</td>
								<td>
									{getDateTime(user?.created_at, 'mm dd yyyy')}, {getDateTime(user?.created_at, undefined, false)}
								</td>
								<td>
									<div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
										<Link to={Approutes.dashboard.interns.use_intern(user?.id)}>
											<EyeOpen />
										</Link>
										{process.env.NODE_ENV === 'development' ? (
											<DeleteButton
												disabled={isDeleting}
												onClick={() => {
													if (isDeleting) return;

													setUserToDelete(user);
													setConfirmModal(true);
												}}
											/>
										) : null}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{users?.results?.map((user) => (
				<div key={user?.id} className="mobile-card">
					<div className="flexed">
						<div>
							<b>Intern's Name</b>
							<p>{user?.name || 'N/A'}</p>
						</div>
						<div className="action-con">
							<Link to={Approutes.dashboard.interns.use_intern(user?.id)}>
								<EyeOpen />
							</Link>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Applicant Type</b>
							<p>{user?.applicant_type || 'N/A'}</p>
						</div>
						<div>
							<b>Add-Ons Plan</b>
							<p>{user?.business_plan?.length ? user?.business_plan?.join(', ') : 'N/A'}</p>
						</div>
					</div>
					<div className="flexed">
						<div>
							<b>Program Start </b>
							<p>{user?.start_date ? getDateTime(user?.start_date, 'mm dd yyyy') : 'N/A'}</p>
						</div>
						<div>
							<b>Program Timeline</b>
							<p>{user?.program_duration || 'N/A'}</p>
						</div>
					</div>
					<div>
						<b>Registration Time</b>
						<p>
							{getDateTime(user?.created_at, 'mm dd yyyy')}, {getDateTime(user?.created_at, undefined, false)}
						</p>
					</div>
				</div>
			))}

			{confirmModal ? (
				<ConfirmModal
					title="Delete User"
					body={
						<>
							Are you sure you want to delete <b>{userToDelete?.email}</b> account ?<br />
							This action <b style={{ color: '#ff7542' }}>cannot be reversed</b>
						</>
					}
					delayYesInSeconds={5}
					handleClose={() => setConfirmModal(false)}
					handleNo={() => setConfirmModal(false)}
					handleYes={() => userToDelete?.id && handleDelete(userToDelete?.id)}
				/>
			) : null}
		</>
	);
};
export default InternTable;
