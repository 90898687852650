import styled from 'styled-components';

export const Container = styled.div`
	height: 20px;

	svg {
		cursor: pointer;

		path {
			fill: transparent !important;
			stroke: #4060ea;
		}
		:hover {
			path {
				stroke: tomato;
			}
		}
	}
`;
