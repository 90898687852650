import { useMutation, useQuery } from '@tanstack/react-query';
import { useAxiosPrivate } from './useAxiosPrivate';
import { Appurls } from '../constants';
import { useDecodedUser } from './useDecodedUser';
import { handleApiError, isInstitutionAdmin, isIntern, isStartUp } from '../utilities';
import { useNotify } from './useNotify';
import { AxiosError } from 'axios';

export const useUser = () => {
	const user = useDecodedUser();
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(
					isStartUp(user)
						? Appurls.user.use_startup_user
						: isIntern(user)
						? Appurls.user.use_intern_user
						: isInstitutionAdmin(user)
						? Appurls.institution_admin.user_profile
						: Appurls.user.use_user(user?.user_id || '')
				)
				.then((response) => response?.data),
		queryKey: ['user', { id: user?.user_id }],
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
};
export const useGetUserById = ({ id = '' }) => {
	const user = useDecodedUser();
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate.get(id ? Appurls.user.use_user(id) : Appurls.user.use_user(user?.user_id || '')).then((response) => response?.data),
		queryKey: ['user-id', { id: id ? id : user?.user_id }],
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
	});
};

export const usePatchUser = () => {
	const user = useDecodedUser();
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (data) => axiosPrivate.patch(Appurls.user.use_user(user?.user_id || ''), data),
	});
};
export const useDeleteUser = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (user_id: string) => axiosPrivate.delete(Appurls.user.use_user(user_id)),
	});
};

export const useGetResources = ({ page = 1, page_size = 5, search = '' }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.project.get_supervisors_resources}?page=${page}&page_size=${page_size}&search=${search}`)
				.then((response) => response?.data),
		queryKey: ['resources', { page, page_size, search }],
	});
};

export const useGetApplicantLogsForSupervisor = ({ projectId = '', internId = '', page = 1, page_size = 5 }) => {
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () =>
			axiosPrivate
				.get(`${Appurls.project.get_applicant_logs_for_supervisor(projectId, internId)}?page=${page}&page_size=${page_size}`)
				.then((response) => response?.data),
		queryKey: ['applicant_logs_for_supervisor', { page, page_size, projectId, internId }],
	});
};

export const useResubmitProfile = () => {
	const axiosPrivate = useAxiosPrivate();

	return useMutation({
		mutationFn: (id: string) => axiosPrivate.post(Appurls.user.resubmit_profile(id)),
	});
};

export const useGetResourcesInProject = () => {
	const notify = useNotify();
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.user.get_active_resources).then((response) => response?.data),
		queryKey: ['active-resources'],
		onError: (error) =>
			notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'active_resources' } }),
	});
};

export const useGetStartupsIAmWith = () => {
	const notify = useNotify();
	const axiosPrivate = useAxiosPrivate();

	return useQuery({
		queryFn: () => axiosPrivate.get(Appurls.user.get_my_startups).then((response) => response?.data),
		queryKey: ['my-startups'],
		onError: (error) => notify({ message: handleApiError(error as AxiosError), status: 'error', toastOptions: { toastId: 'my_startups' } }),
	});
};
