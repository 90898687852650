import { ReactNode, useEffect, useState } from 'react';
import { CloseModal } from '../../../assets/svgs';
import { Button } from '../../../ui';
import { Container } from './styles';

const ConfirmModal = ({
	title = 'Confirm Action',
	body = 'Are you sure you want to proceed ?',
	delayYesInSeconds = 0,
	handleClose,
	handleNo,
	handleYes,
}: {
	title: string;
	body: string | ReactNode;
	delayYesInSeconds?: number;
	handleClose: () => void;
	handleNo: () => void;
	handleYes: () => void;
}) => {
	const [canClickYesIn, setCanClickYesIn] = useState(delayYesInSeconds);

	useEffect(() => {
		const timer = setInterval(() => {
			setCanClickYesIn((prev) => {
				if (prev <= 1) clearInterval(timer);

				return prev >= 1 ? prev - 1 : 0;
			});
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	return (
		<Container>
			<div>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>{title}</h2>
				<p>{body}</p>

				<div className="btn-group">
					<Button variant="secondary" onClick={() => handleNo()}>
						No
					</Button>
					<Button disabled={canClickYesIn >= 1} onClick={() => handleYes()}>
						Yes {canClickYesIn >= 1 ? `(${canClickYesIn})` : null}
					</Button>
				</div>
			</div>
		</Container>
	);
};
export default ConfirmModal;
