import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
	Playground,
	PageNotFound,
	ChooseAccount,
	Startup,
	Intern,
	Login,
	ForgotPassword,
	CompleteRegistration,
	Welcome,
	Project,
	ProjectCreate,
	ProjectDetails,
	ProjectTaskDetails,
	Talents,
	Overview,
	MyTasks,
	MyTaskDetails,
	Timesheet,
	Settings,
	Password,
	Supervisor,
	VerifySupervisorAccount,
	Resources,
	Notifications,
	Profile,
	Home,
	TimesheetForSupervisor,
	AcceptInviteAsSupervisor,
	AddOns,
	ProjectDetailsForIntern,
	Billing,
	Messages,
	ExtraPicks,
	InstitutionSignup,
	AcceptInviteAsInstitutionDepartmentHead,
	AcceptInviteAsInstitutionSupervisor,
	AcceptInviteAsInstitutionIntern,
	InstitutionInternSupervisor,
	Help,
} from './views';
import { ApplyLayout, AuthLayout } from './layout';
import { Approutes } from './constants';
import { GlobalStyles, theme } from './base';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
	AdminOnly,
	InternOnly,
	RequireApprovedProfile,
	RequireAuth,
	RequireCompleteProfile,
	RequireNoAuth,
	ScrollToTop,
	StartupOnly,
	SupervisorOnly,
	UseAnalytics,
} from './components';
import {
	AdminDashboardIntern,
	Blog,
	Blogs,
	Businesses,
	Concerns,
	Coupons,
	Finances,
	Interns,
	Matches,
	AdminOverview,
	AdminDashboard,
} from './views/Dashboard/pages';
import { InstitutionAdminOnly, InstitutionUsersOnly } from './views/InstitutionAdmin/components';
import {
	InstitutionAdminDashboard,
	InstitutionAdminDepartment,
	InstitutionAdminDepartmentDetailPage,
	InstitutionAdminDepartmentHead,
	InstitutionAdminInternDetailPage,
	InstitutionAdminInterns,
	InstitutionAdminSupervisorDetailPage,
	InstitutionAdminSupervisors,
} from './views/InstitutionAdmin/pages';
import {
	InstitutionHeadDepartmentDetailPage,
	InstitutionHeadInterns,
	InstitutionHeadSupervisorDetailPage,
	InstitutionHeadSupervisors,
} from './views/InstitutionDepartmentHead/pages';
import { InstitutionDepartmentHeadOnly } from './views/InstitutionDepartmentHead/components';
import { InstitutionInternLogs, InstitutionSupervisorDashboard, InstitutionSupervisorInterns } from './views/InstitutionSupervisor/pages';
import { InstitutionSupervisorOnly } from './views/InstitutionSupervisor/components';
import { isInstitutionIntern } from './utilities';
import { useDecodedUser } from './hooks';

function App() {
	const user = useDecodedUser();

	return (
		<>
			<ThemeProvider theme={theme}>
				<GlobalStyles />

				<BrowserRouter>
					<Routes>
						{/* Playground page for developers. Not visible in production */}
						{process.env.NODE_ENV === 'development' && <Route path={Approutes.playground} element={<Playground />} />}

						<Route element={<RequireNoAuth />}>
							<Route element={<AuthLayout />}>
								<Route path={Approutes.signup.index} element={<ChooseAccount />} />
								<Route path={Approutes.signup.startup} element={<Startup />} />
								<Route path={Approutes.signup.intern} element={<Intern />} />
								<Route path={Approutes.signup.institution} element={<InstitutionSignup />} />
								<Route path={Approutes.login} element={<Login />} />
								<Route path={Approutes.forgot_password} element={<ForgotPassword />} />
							</Route>
						</Route>

						<Route element={<RequireAuth />}>
							<Route element={<RequireCompleteProfile />}>
								<Route element={<ApplyLayout />}>
									<Route path={Approutes.home} element={<Home />} />
									<Route path={Approutes.welcome} element={<Welcome />} />
									<Route path={Approutes.profile} element={<Profile />} />
									<Route path={Approutes.complete_registration} element={<CompleteRegistration />} />

									<Route element={<Settings />}>
										<Route path={Approutes.settings.index} element={<Navigate to={Approutes.settings.password} replace />} />
										<Route path={Approutes.settings.billing} element={<Billing />} />
										<Route path={Approutes.settings.password} element={<Password />} />
										<Route path={Approutes.settings.notifications} element={<Notifications />} />
										<Route path={Approutes.settings.help} element={<Help />} />
									</Route>
									<Route element={<SupervisorOnly />}>
										<Route path={Approutes.supervisor.resources} element={<Resources />} />
										<Route path={Approutes.supervisor.intern_timesheet} element={<TimesheetForSupervisor />} />
									</Route>
									<Route element={<AdminOnly />}>
										<Route path={Approutes.dashboard.index} element={<AdminDashboard />} />
										<Route path={Approutes.dashboard.overview} element={<AdminOverview />} />
										<Route path={Approutes.dashboard.businesses} element={<Businesses />} />
										<Route path={Approutes.dashboard.blog} element={<Blog />} />
										<Route path={Approutes.dashboard.blogs} element={<Blogs />} />
										<Route path={Approutes.dashboard.concerns} element={<Concerns />} />
										<Route path={Approutes.dashboard.coupons} element={<Coupons />} />
										<Route path={Approutes.dashboard.interns.index} element={<Interns />} />
										<Route path={Approutes.dashboard.interns.intern} element={<AdminDashboardIntern />} />
										<Route path={Approutes.dashboard.finances} element={<Finances />} />
										<Route path={Approutes.dashboard.matches} element={<Matches />} />
									</Route>

									<Route element={<RequireApprovedProfile />}>
										<Route path={Approutes.messages.index} element={<Messages />} />

										<Route element={<StartupOnly />}>
											<Route path={Approutes.startup.projects.index} element={<Project />} />
											<Route path={Approutes.startup.projects.create_project} element={<ProjectCreate />} />
											<Route path={Approutes.startup.projects.project_details} element={<ProjectDetails />} />
											<Route path={Approutes.startup.projects.task_details} element={<ProjectTaskDetails />} />
											<Route path={Approutes.startup.early_starters} element={<Navigate to={Approutes.startup.talents} replace />} />
											<Route path={Approutes.startup.talents} element={<Talents />} />
											<Route path={Approutes.startup.extra_picks} element={<ExtraPicks />} />
										</Route>
										<Route element={<InternOnly />}>
											<Route path={Approutes.intern.projects.project_details} element={<ProjectDetailsForIntern />} />
											<Route path={Approutes.intern.add_ons} element={<AddOns />} />
											<Route path={Approutes.intern.tasks.my_tasks} element={<MyTasks />} />
											<Route path={Approutes.intern.tasks.my_project_tasks} element={<MyTasks />} />
											<Route path={Approutes.intern.tasks.task_details} element={<MyTaskDetails />} />
											<Route path={Approutes.intern.tasks.overview} element={<Overview />} />
											<Route
												path={Approutes.intern.supervisor}
												element={isInstitutionIntern(user) ? <InstitutionInternSupervisor /> : <Supervisor />}
											/>
											<Route path={Approutes.intern.tasks.logs} element={<Timesheet />} />
											<Route path={Approutes.intern.tasks.project_logs} element={<Timesheet />} />
										</Route>
										<Route element={<InstitutionAdminOnly />}>
											<Route path={Approutes.institution_admin.index} element={<InstitutionAdminDashboard />} />
											<Route path={Approutes.institution_admin.deparments} element={<InstitutionAdminDepartment />} />
											<Route path={Approutes.institution_admin.deparment_heads} element={<InstitutionAdminDepartmentHead />} />
											<Route path={Approutes.institution_admin.deparment} element={<InstitutionAdminDepartmentDetailPage />} />
											<Route path={Approutes.institution_admin.department_supervisor} element={<InstitutionAdminSupervisorDetailPage />} />
											<Route path={Approutes.institution_admin.supervisors} element={<InstitutionAdminSupervisors />} />
											<Route path={Approutes.institution_admin.interns} element={<InstitutionAdminInterns />} />
										</Route>
										<Route element={<InstitutionDepartmentHeadOnly />}>
											<Route path={Approutes.institution_department_head.index} element={<InstitutionHeadDepartmentDetailPage />} />
											<Route path={Approutes.institution_department_head.supervisors} element={<InstitutionHeadSupervisors />} />
											<Route path={Approutes.institution_department_head.supervisor} element={<InstitutionHeadSupervisorDetailPage />} />
											<Route path={Approutes.institution_department_head.interns} element={<InstitutionHeadInterns />} />
										</Route>
										<Route element={<InstitutionSupervisorOnly />}>
											<Route path={Approutes.institution_supervisor.index} element={<InstitutionSupervisorDashboard />} />
											<Route path={Approutes.institution_supervisor.view_intern_logs} element={<InstitutionInternLogs />} />
											<Route path={Approutes.institution_supervisor.interns} element={<InstitutionSupervisorInterns />} />
										</Route>
										<Route element={<InstitutionUsersOnly />}>
											<Route path={Approutes.institution_admin.intern} element={<InstitutionAdminInternDetailPage />} />
										</Route>
									</Route>
								</Route>
							</Route>
						</Route>

						<Route path={Approutes.supervisor.verify_account} element={<VerifySupervisorAccount />} />
						<Route path={Approutes.supervisor.accept_invite} element={<AcceptInviteAsSupervisor />} />
						<Route path={Approutes.institution_supervisor.accept_invite} element={<AcceptInviteAsInstitutionSupervisor />} />
						<Route path={Approutes.institution_department_head.accept_invite} element={<AcceptInviteAsInstitutionDepartmentHead />} />
						<Route path={Approutes.institution_intern.accept_invite} element={<AcceptInviteAsInstitutionIntern />} />

						<Route path="*" element={<PageNotFound />} />
					</Routes>

					<ScrollToTop />
					<UseAnalytics />
				</BrowserRouter>

				<ToastContainer limit={1} autoClose={3500} newestOnTop={true} closeButton={false} position="top-center" hideProgressBar={true} />
			</ThemeProvider>

			<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
		</>
	);
}

export default App;
