import { Link } from 'react-router-dom';
import { CloseModal } from '../../../../assets/svgs';
import { Container } from './styles';
import { Approutes } from '../../../../constants';

const InviteResource = ({ handleResourceClick, handleClose }) => {
	return (
		<Container>
			<div>
				<CloseModal tabIndex={0} onClick={handleClose} />
				<h2>Invite Resource</h2>
				<p>Choose your type of resources to invite</p>

				<button onClick={handleResourceClick}>
					<b>Resource(s) in Project</b>
					<span>These resources already in another Project </span>
				</button>
				<br />

				<Link to={Approutes.startup.early_starters}>
					<button>
						<b>New Resources (Talents)</b>
						<span>Add a new resources to this project</span>
					</button>
				</Link>
			</div>
		</Container>
	);
};
export default InviteResource;
