import { Languages } from './languages';
import { STARTUP_PAYMENT_PACKAGE } from './packages';

// export const COMPANY_SECTORS = [
// 	'Healthtech',
// 	'Fintech',
// 	'Edtech',
// 	'E-commerce',
// 	'SaaS (Software as a Service)',
// 	'AI and Machine Learning',
// 	'Renewable Energy',
// 	'Biotech',
// 	'FoodTech',
// 	'CleanTech',
// 	'Proptech (Property Technology)',
// 	'TravelTech',
// 	'FashionTech',
// 	'Insurtech',
// 	'IoT (Internet of Things)',
// 	'Robotics',
// 	'Agtech (Agricultural Technology)',
// 	'LegalTech',
// 	'HRtech (Human Resources Technology)',
// 	'Gaming and Entertainment Tech',
// ];

export const COMPANY_SIZE = ['0 - 10', '11 - 20', '21 - 30', '31 - 40', '41 - 50', '51 - 60', '61 - 70', '71 - 80', '81 - 90', '91 - 100'];

export const APPLICANT_SKILLS = [
	'Programming and Coding Skills',
	'Problem Solving and Critical Thinking',
	'Data Analysis',
	'Web Development',
	'Mobile App Development',
	'Database Management',
	'Cybersecurity',
	'Cloud Computing',
	'AI and Machine Learning',
	'Data Science',
	'User Experience (UX) Design',
	'User Interface (UI) Design',
	'Agile and Scrum Methodologies',
	'Version Control (e.g., Git)',
	'Software Development Life Cycle (SDLC)',
	'Networking',
	'Linux/Unix Proficiency',
	'Data Structures and Algorithms',
	'Communication and Collaboration',
	'Project Management',
	'DevOps Practices',
	'Internet of Things (IoT) Knowledge',
	'Big Data Technologies (e.g., Hadoop)',
	'Computer Graphics',
	'Test-Driven Development (TDD)',
	'Programming Languages (e.g., Python, Java, JavaScript)',
	'Front-End and Back-End Development',
	'Machine Learning Frameworks (e.g., TensorFlow, PyTorch)',
	'Knowledge of Databases (SQL, NoSQL)',
	'Scripting (e.g., Bash, PowerShell)',
];

export const INTERESTED_ROLES = [
	'Project Manager',
	'Product Manager',
	'Business Analyst',
	'Product Designer',
	'Data Analyst',
	'Product Owner',
	'Scrum Master',
	'Devops Engineer',
	'Web Developer',
	'Mobile Developer',
	'Cybersecurity',
];

export const INTERESTED_SECTOR = [
	'E-commerce and Online Retail',
	'Financial Technology (FinTech)',
	'Healthcare Technology (HealthTech)',
	'Artificial Intelligence (AI) and Machine Learning',
	'Cybersecurity',
	'Renewable Energy and CleanTech',
	'EdTech (Educational Technology)',
	'Gaming and Esports',
	'Autonomous Vehicles and Transportation Tech',
	'Blockchain and Cryptocurrency',
	'Aerospace and Aviation Technology',
	'AgTech (Agricultural Technology)',
	'Space Exploration and Aerospace',
	'Environmental and Conservation Tech',
	'Entertainment and Media Tech',
	'Government Tech (GovTech)',
	'FoodTech and Food Delivery',
	'Travel and Hospitality Tech',
	'Smart Cities and Urban Planning',
	'Supply Chain and Logistics Tech',
];

export const COMPANY_SECTORS = [...INTERESTED_SECTOR];

export const LEVEL_OF_EDUCATION = ['BSc', 'MSc', 'PHD', 'Nano Degree'];

export const APPLICANT_TYPE = ['University Internship', 'Summer Internship', 'Graduate Internship / Route'];

export const PROJECT_DURATIONS = ['3 months', '6 months'];

export const INTERNSHIP_DURATIONS = ['3 months', '6 months'];

export const filterApplicants = [
	{
		name: 'Level of Education',
		options: LEVEL_OF_EDUCATION,
		queryKey: 'highest_edu_level',
	},
	// {
	// 	name: 'Years of Experience',
	// 	options: ['3 Months', '6 Months', '1 Year+'],
	// 	queryKey: 'experience_level',
	// },
	{
		name: 'Industry Interest',
		options: INTERESTED_SECTOR,
		queryKey: 'interested_sector',
	},
	{
		name: 'Language',
		options: Languages,
		queryKey: 'languages',
	},
	{
		name: 'Applicant Type',
		options: APPLICANT_TYPE,
		queryKey: 'applicant_type',
	},
];

export const businessesFilters = [
	{
		name: 'Business Plan',
		options: STARTUP_PAYMENT_PACKAGE.map((item) => item.title),
		queryKey: 'business_plan',
	},
	// {
	// 	name: 'Payment Status',
	// 	options: ['Has Paid', 'Not Paid'],
	// 	queryKey: 'payment_status',
	// },
];

export const internsFilters = [
	{
		name: 'Applicant Type',
		options: APPLICANT_TYPE,
		queryKey: 'applicant_type',
	},
	// {
	// 	name: 'Add-Ons Plan',
	// 	options: INTERN_PAYMENT_PACKAGE.add_ons.map((item) => item.title),
	// 	queryKey: 'addons_plan',
	// },
	{
		name: 'Program Duration',
		options: INTERNSHIP_DURATIONS,
		queryKey: 'program_duration',
	},
];

export const statisticsFilters = [
	{
		name: 'Today',
		options: [],
		queryKey: 'today',
	},
	{
		name: 'This Week',
		options: [],
		queryKey: 'this_week',
	},
	{
		name: 'This Month',
		options: [],
		queryKey: 'this_month',
	},
	{
		name: 'This Year',
		options: [],
		queryKey: 'this_year',
	},
];

export const STARTUP_CONCERNS = ['Abuse', 'Delay in Tasks', 'Delay in Response'];

export const INTERN_CONCERNS = ['Abuse', 'Inactivity', 'Delay in Response'];
