import { useNotify, useUpdateInternProfile } from '../../../hooks';
import { Button, FileGroup, SelectGroup } from '../../../ui';
import { APPLICANT_SKILLS, INTERESTED_ROLES, INTERESTED_SECTOR } from '../../../constants';
import { handleApiError } from '../../../utilities';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { FileImage } from '../../../assets/svgs';

const IDDocument = ({ formData, handleChange }) => {
	const notify = useNotify();
	const queryClient = useQueryClient();
	const [hasSubmitted, setHasSubmitted] = useState(false);
	const [documentType, setDocumentType] = useState('certification');

	const { mutate: updateProfile, isLoading } = useUpdateInternProfile();

	const handleSubmit = async (event) => {
		event.preventDefault();

		const axiosFormData = new FormData();

		if (documentType === 'enrollment' && formData.enrolment_document.file) {
			axiosFormData.append('enrollment_doc', formData.enrolment_document.file);
		} else if (documentType === 'certification' && formData.certification_document.file) {
			axiosFormData.append('certification_docs', formData.certification_document.file);
		}

		formData.supplementary_document.file && axiosFormData.append('supplimentary_docs', formData.supplementary_document.file);
		formData.id_card.file && axiosFormData.append('id_card', formData.id_card.file);

		for (let i = 0; i < formData.skills.length; i++) {
			axiosFormData.append('skills', formData.skills[i]);
		}
		for (let i = 0; i < formData.interested_role.length; i++) {
			axiosFormData.append('interested_role', formData.interested_role[i]);
		}
		for (let i = 0; i < 1; i++) {
			axiosFormData.append('languages', 'English');
		}
		for (let i = 0; i < formData.sector.length; i++) {
			axiosFormData.append('interested_sector', formData.sector[i]);
		}

		updateProfile(axiosFormData, {
			onSuccess: () => {
				setHasSubmitted(true);
				queryClient.invalidateQueries(['user-id']);
				queryClient.invalidateQueries(['user']);
			},
			onError: (error) => notify({ message: handleApiError(error), status: 'error', toastOptions: { toastId: 'profile_update' } }),
		});
	};

	return (
		<>
			<form onSubmit={handleSubmit}>
				<h2>Internship, Interest & Skills</h2>
				<p>Provide information to know your educational background & Your Exprience</p>

				<div>
					<SelectGroup
						label="My Skills"
						name="skills"
						onChange={handleChange}
						placeholder="Select your skills"
						value={formData.skills}
						options={APPLICANT_SKILLS}
						max={3}
						required
						variant="multiple"
					/>
					<SelectGroup
						label="Interested Role"
						name="interested_role"
						onChange={handleChange}
						placeholder="Select Interested Role"
						value={formData.interested_role}
						options={INTERESTED_ROLES}
						max={2}
						required
						variant="multiple"
					/>
					<SelectGroup
						label="Interested Sector (3 Max)"
						name="sector"
						onChange={handleChange}
						placeholder="Select Interested Sector"
						value={formData.sector}
						options={INTERESTED_SECTOR}
						required
						max={3}
						variant="multiple"
					/>
					<SelectGroup
						label="Required Document"
						name="required_doc"
						onChange={handleChange}
						placeholder="Select Document"
						value={formData.required_doc}
						options={['ID Card', 'Certification/Enrolment Document']}
						required
						shouldFilterByDefault={false}
					/>
					{formData.required_doc === 'ID Card' ? (
						<>
							<FileGroup
								label="Institution ID Card"
								name="id_card"
								onChange={handleChange}
								helpTextTwo="PNG, JPEG. Max size of 1MB"
								accept="image/png, image/jpeg"
								maxSize={1}
								moreInfo="Upload an image of your institution's ID card"
								value={formData.id_card}
								required
								openByDefault={new Date() < new Date('2025-01-01')}
								icon={FileImage}
							/>
							<FileGroup
								label="Supplementary Documents"
								name="supplementary_document"
								onChange={handleChange}
								helpTextTwo="PNG, JPEG, Doc, PDF. Max size of 1MB"
								accept="image/png, image/jpeg, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
								maxSize={1}
								moreInfo="Upload a supporting document that proves you possess the skills selected above or that you attended your institution"
								value={formData.supplementary_document}
							/>
						</>
					) : (
						<div className="document-group">
							{documentType === 'enrollment' ? (
								<FileGroup
									label="Enrolment Document"
									name="enrolment_document"
									onChange={handleChange}
									helpTextTwo="PNG, JPEG, Doc, PDF. Max size of 1MB"
									accept="image/png, image/jpeg, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
									maxSize={1}
									value={formData.enrolment_document}
									required
								/>
							) : (
								<FileGroup
									label="Certification Document"
									name="certification_document"
									onChange={handleChange}
									helpTextTwo="PNG, JPEG, Doc, PDF. Max size of 1MB"
									accept="image/png, image/jpeg, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
									maxSize={1}
									moreInfo="Upload an image or document of a valid certificate you have of a training you took on a skill"
									value={formData.certification_document}
									required
								/>
							)}
							<button
								type="button"
								className="text-underline"
								style={{
									color: '#4060ea',
									fontSize: '0.75rem',
									fontWeight: '500',
									backgroundColor: 'transparent',
									marginTop: '1rem',
								}}
								onClick={() => setDocumentType((prev) => (prev === 'enrollment' ? 'certification' : 'enrollment'))}
							>
								{documentType === 'enrollment'
									? "Don't have an enrollment document? Upload certification"
									: "Don't have an certification document? Upload enrollment"}
							</button>
						</div>
					)}
				</div>

				<div className="btn-group">
					<Button loading={isLoading || hasSubmitted} style={{ gridColumn: '1/3' }}>
						Submit Profile
					</Button>
				</div>
			</form>
		</>
	);
};

export default IDDocument;
