import { useRef, useState } from 'react';
import { Container } from './styles';
import { Loader, Pagination, PalletList, SearchBar } from '../../../../ui';
import { useGetDashboardUsers, useGetDashboardStats, useGetDashboardInstitutions } from '../../../../hooks';
import { useSearchParams } from 'react-router-dom';
import { getPreviousSearchParams } from '../../../../utilities';
import { businessesFilters, internsFilters, queryStrings, statisticsFilters } from '../../../../constants';
import { DashboardAmountPaid, DashboardAmountRefunded, DashboardBusiness, DashboardInterns, ProjectEmpty } from '../../../../assets/svgs';
import { BusinessTable, InstitutionTable, InternTable } from '../../components';
import { Filter } from '../../../../components';
import { useCallback } from 'react';
import { FilterValue, GeneralChangeEventType, InstitutionUserType, ServerGetResponse } from '../../../../types';

type ControlType = 'businesses' | 'interns' | 'institutions';

const Overview = () => {
	const pageSize = useRef(6);
	const [searchParams, setSearchParams] = useSearchParams();
	const [page, setPage] = useState(1);
	const [control, setControl] = useState<ControlType>('businesses');
	const [formData, setFormData] = useState({
		selected_applicants: [],
		search: '',
		filter_stats: (() => {
			let state: FilterValue = {};
			statisticsFilters.forEach((option) => (state[option?.name] = { value: '', queryKey: option?.queryKey }));

			return state;
		})(),
		filter: (() => {
			let state: FilterValue = {};
			(control === 'businesses' ? businessesFilters : internsFilters).forEach(
				(option) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
			);

			return state;
		})(),
	});

	const { data: stats, isLoading: statsLoading } = useGetDashboardStats();
	const {
		data: users,
		isLoading,
		isError,
	} = useGetDashboardUsers({ page, page_size: pageSize.current, search: formData.search, user: control });

	const {
		data: institutionsTyped,
		isLoading: isLoadingInstitution,
		isError: isInstitutionError,
	} = useGetDashboardInstitutions({ page, page_size: pageSize.current, search: formData.search, enabled: control === 'institutions' });

	const institutions = institutionsTyped as ServerGetResponse<InstitutionUserType>;

	const statsData = [
		{
			title: 'Total Businesses',
			body: stats?.data?.businesses || 0,
			icon: <DashboardBusiness />,
		},
		{
			title: 'Total Interns',
			body: stats?.data?.interns || 0,
			icon: <DashboardInterns />,
		},
		{
			title: 'Amount Paid',
			body: '£' + (stats?.data?.amount_paid || 0),
			icon: <DashboardAmountPaid />,
		},
		{
			title: 'Amount Refunded',
			body: '£' + (stats?.data?.amount_refunded || 0),
			icon: <DashboardAmountRefunded />,
		},
	];

	const handleItemClick = (id = '', type = 'business') => {
		let previousParams = getPreviousSearchParams(searchParams);

		delete previousParams[queryStrings.applicantId];
		delete previousParams[queryStrings.businessId];
		delete previousParams[queryStrings.institutionId];

		previousParams = {
			...previousParams,
			[type === 'business'
				? queryStrings.businessId
				: type === 'interns'
				? queryStrings.applicantId
				: type === 'institutions'
				? queryStrings.institutionId
				: '']: id,
		};
		setSearchParams(previousParams, { replace: true });
	};
	const handleChange: GeneralChangeEventType = useCallback((event, name, value) => {
		name = name || event?.target.name || '';
		value = value || event?.target.value || '';

		setFormData((prev) => ({ ...prev, [name as string]: value }));
	}, []);
	const handleControl = (value: ControlType) => {
		setPage(1);
		setControl(value);

		handleChange(null, 'search', '');
		handleChange(
			null,
			'filter',
			(() => {
				let state: FilterValue = {};
				(value === 'businesses' ? businessesFilters : control === 'interns' ? internsFilters : control === 'institutions' ? [] : []).forEach(
					(option) => (state[option?.name] = { value: '', queryKey: option?.queryKey })
				);

				return state;
			})()
		);
	};

	return (
		<Container>
			<div className="header">
				<div>
					<h4>Overview</h4>
					{/* <p>Update your photo and personal details</p> */}
				</div>

				<Filter name="filter_stats" onChange={handleChange} value={formData.filter_stats} options={statisticsFilters} />
			</div>

			<PalletList loading={statsLoading} data={statsData} />

			<div className="table-header">
				<div className="controls">
					<button className={control === 'businesses' ? 'active' : ''} onClick={() => handleControl('businesses')}>
						Businesses
					</button>
					<button className={control === 'interns' ? 'active' : ''} onClick={() => handleControl('interns')}>
						Interns
					</button>
					<button className={control === 'institutions' ? 'active' : ''} onClick={() => handleControl('institutions')}>
						Institutions
					</button>
				</div>

				<div className="filter-con">
					<SearchBar name="search" placeholder="Search through applicants" handleChange={handleChange} value={formData.search} />
					<Filter
						name="filter"
						onChange={handleChange}
						value={formData.filter}
						options={control === 'businesses' ? businessesFilters : control === 'interns' ? internsFilters : control === 'institutions' ? [] : []}
					/>
				</div>
			</div>

			{isLoading || (isLoadingInstitution && control === 'institutions') ? (
				<center>
					<Loader />
				</center>
			) : isError || isInstitutionError ? (
				<center>
					<div>
						<ProjectEmpty />
						<p className="error-message">Unable to fetch {control} at the moment</p>
					</div>
				</center>
			) : !users?.results || users?.results?.length === 0 ? (
				<div className="empty-state">
					<ProjectEmpty />
					<p className="error-message">No {control === 'businesses' ? 'business' : 'intern'} to display</p>
				</div>
			) : control === 'businesses' ? (
				<BusinessTable page={page} users={users} handleItemClick={(id: string) => handleItemClick(id, 'business')} />
			) : control === 'interns' ? (
				<InternTable page={page} users={users} />
			) : control === 'institutions' ? (
				<InstitutionTable page={page} users={institutions} handleItemClick={(id: string) => handleItemClick(id, 'institutions')} />
			) : null}

			<Pagination
				page={page}
				setPage={setPage}
				maxPages={Math.ceil((control === 'institutions' ? institutions?.total : users?.total) / pageSize.current)}
				disabled={isLoading}
			/>
		</Container>
	);
};
export default Overview;
